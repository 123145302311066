import cookies from 'js-cookie';
import { getDeviceId } from './device';
import { addParam } from './yoda';
/** 分享时用的 */
export var getShareId = function getShareId() {
  return "".concat(getDeviceId(), "_").concat(new Date().getTime());
};
// 为什么都改成函数了，因为端外登录有请求接口注入的过程，直接写成静态的话，注入前是获取不到的
export var getShareUid = function getShareUid() {
  return cookies.get('userId') || cookies.get('ud') || '';
};
export var getShareDeviceId = getDeviceId;
export var addShareParams = function addShareParams(url, share_item_type) {
  if (typeof url === 'string') {
    url = addParam(url, {
      share_id: getShareId(),
      share_item_type: share_item_type,
      share_device_id: getShareDeviceId(),
      share_uid: getShareUid(),
      share_bucket: cookies.get('bucket') || ''
    });
  } else {
    url.searchParams.set('share_id', getShareId());
    url.searchParams.set('share_item_type', share_item_type);
    url.searchParams.set('share_device_id', getShareDeviceId());
    url.searchParams.set('share_uid', getShareUid());
    url.searchParams.set('share_bucket', cookies.get('bucket') || '');
  }
};