function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import axios from 'axios';
import { merge } from 'lodash-es';
import qs from 'qs';
import cookie from 'js-cookie';
// 为所有请求添加请求时间戳和请求uri，后端可能依赖这个
var requestHeaderInterceptor = {
  success: function success() {
    var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var sConfig = merge({}, config);
    return sConfig;
  },
  fail: function fail(error) {
    return Promise.reject(error);
  }
};
// 为get请求添加随机数
var randomTimeInterceptor = {
  success: function success() {
    var _sConfig$method;
    var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var sConfig = merge({}, config);
    sConfig.params = sConfig.params || {};
    if (((_sConfig$method = sConfig.method) === null || _sConfig$method === void 0 ? void 0 : _sConfig$method.toUpperCase()) === 'GET') {
      sConfig.params._t = new Date().getTime();
    }
    return sConfig;
  },
  fail: function fail(error) {
    return Promise.reject(error);
  }
};
// 针对sh build-jinx.sh admin的，将/rest/o/w/creator/home这种变为/rest/o/w/creator/admin/home?userId=XXX
var adminApis = ['/rest/o/w/creator/home', '/rest/o/w/creator/settlement/getUserInfo', '/rest/o/w/creator/settlement/getPeriodInfo', '/rest/o/w/creator/statis/video/info', '/rest/o/w/creator/statis/video/list', '/rest/o/w/creator/statis/u/info', '/rest/o/w/creator/statis/overview', '/rest/o/w/creator/statis/fans/view', '/rest/o/w/creator/analysis/partnerProfile', '/rest/o/w/creator/analysis/secondCreator', '/rest/o/w/creator/scout/home', '/rest/o/w/creator/scout/qualification/check', '/rest/o/w/creator/scout/invitee/award/list', '/rest/o/w/creator/scout/invitation/list', '/rest/o/w/creator/scout/rule', '/rest/o/w/creator/fund/detail', '/rest/o/w/creator/fund/rankList'];
export var adminInterceptor = {
  success: function success(config) {
    var sConfig = merge({}, config);
    var originalUrl = sConfig.url || '';
    if (adminApis.includes(originalUrl)) {
      sConfig.url = originalUrl === null || originalUrl === void 0 ? void 0 : originalUrl.split('/rest/o/w/creator/').join('/rest/o/w/creator/admin/');
      if (sConfig.method === 'post') {
        // 初始化data
        if (!sConfig.data) {
          sConfig.data = {};
        }
        sConfig.data = _objectSpread(_objectSpread({}, sConfig.data), {}, {
          userId: new URL(window.location.href).searchParams.get('userId')
        });
      } else if (sConfig.method === 'get') {
        // 初始化params
        if (!sConfig.params) {
          sConfig.params = {};
        }
        sConfig.params = _objectSpread(_objectSpread({}, sConfig.params), {}, {
          userId: new URL(window.location.href).searchParams.get('userId')
        });
      }
    }
    return sConfig;
  },
  fail: function fail(error) {
    return Promise.reject(error);
  }
};
// 为get请求添加随机数
var requestInterceptor = [randomTimeInterceptor.success, requestHeaderInterceptor.success];
var requestInterceptorHandler = {
  success: function success() {
    var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var sConfig = merge({}, config);
    sConfig.params = sConfig.params || {};
    var fConfig = requestInterceptor.reduce(function (_config, nextFun) {
      return nextFun(_config);
    }, sConfig);
    return fConfig;
  },
  fail: function fail(error) {
    Promise.reject(error);
  }
};
// Add a request interceptor to get add random _t
var config = {
  timeout: 3e4,
  headers: {
    Accept: 'application/json'
  },
  withCredentials: true
};
export var yongdaoInterceptor = {
  success: function success(config) {
    return config;
  },
  fail: function fail(error) {
    Promise.reject(error);
  }
};
function getAxiosInstance() {
  var instance = axios.create(config);
  // Add a request interceptor to loading
  instance.interceptors.request.use(requestInterceptorHandler.success, requestInterceptorHandler.fail);
  if (process.env && process.env.VUE_APP_RUNTIME_ENV === 'admin') {
    instance.interceptors.request.use(adminInterceptor.success, adminInterceptor.fail);
  }
  if (process.env && process.env.VUE_APP_RUNTIME_ENV !== 'production') {
    instance.interceptors.request.use(yongdaoInterceptor.success, yongdaoInterceptor.fail);
  }
  // 统一处理所有的返回，并且抛出异常处理
  var handlerErrorInterceptor = {
    success: function success(response) {
      return response;
    },
    fail: function fail(error) {
      var _error$response, _error$response2;
      if (!error.response) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error.response = {};
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      error.response.data = {
        result: 0 - (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) || 1),
        error_msg: ((_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.statusText) || 'net error'
      };
      return error.response;
    }
  };
  // Add a response interceptor to handler error unversally
  instance.interceptors.response.use(handlerErrorInterceptor.success, handlerErrorInterceptor.fail);
  return instance;
}
function request() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var instance = getAxiosInstance();
  return new Promise(function (resolve, reject) {
    instance.request(config).then(function (response) {
      resolve(response.data);
    }, reject);
  });
}
var kpn = cookie.get('kpn') || new URL(location.href).searchParams.get('kpn') || 'KWAI';
var httpAgent = {
  request: request,
  post: function post(url) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return request(_objectSpread(_objectSpread({
      method: 'POST',
      url: url,
      data: data
    }, config), {}, {
      paramsSerializer: function paramsSerializer(params) {
        params.kpn = kpn;
        return qs.stringify(params);
      }
    }));
  },
  put: function put(url) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return request(_objectSpread({
      method: 'PUT',
      url: url,
      data: data
    }, config));
  },
  get: function get(url) {
    var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return request(_objectSpread(_objectSpread({
      method: 'GET',
      url: url
    }, config), {}, {
      paramsSerializer: function paramsSerializer(params) {
        params.kpn = kpn;
        return qs.stringify(params);
      }
    }));
  },
  delete: function _delete(url) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return request(_objectSpread({
      method: 'DELETE',
      url: url,
      data: data
    }, config));
  }
};
export var http = httpAgent;
export default {
  install: function install(vue) {
    vue.prototype.$http = httpAgent;
  }
};