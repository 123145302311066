import axios from 'axios';
import { adminInterceptor, yongdaoInterceptor } from './axios';
import { kpn } from './device';
// 之前axios.ts封装得太烂了，想以后用这个
export var myhttp = axios.create({
  timeout: 3e4,
  headers: {
    Accept: 'application/json'
  },
  withCredentials: true
});
// 统一处理所有的返回，并且抛出异常处理
var handlerErrorInterceptor = {
  success: function success(response) {
    return response.data;
  },
  fail: function fail(error) {
    var _error$response, _error$response2;
    if (!error.response) {
      error.response = {};
    }
    error.response.data = {
      result: 0 - (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) || 1),
      error_msg: ((_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : _error$response2.statusText) || 'net error'
    };
    return error.response.data;
  }
};
var addKpnInterceptor = {
  success: function success(config) {
    if (!config.params) {
      config.params = {};
    }
    config.params.kpn = kpn;
    return config;
  },
  fail: function fail(error) {
    Promise.reject(error);
  }
};
myhttp.interceptors.response.use(handlerErrorInterceptor.success, handlerErrorInterceptor.fail);
myhttp.interceptors.request.use(addKpnInterceptor.success, addKpnInterceptor.fail);
if (process.env && process.env.VUE_APP_RUNTIME_ENV === 'admin') {
  myhttp.interceptors.request.use(adminInterceptor.success, adminInterceptor.fail);
}
if (process.env && process.env.VUE_APP_RUNTIME_ENV !== 'production') {
  myhttp.interceptors.request.use(yongdaoInterceptor.success, yongdaoInterceptor.fail);
}