function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0) { ; } } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import cookies from "js-cookie";
import Cookie from "./cookie";
import { getQuery } from "./url";
export function isInSnack(ua) {
  return /bulldog/i.test(ua);
}
export function isInUvideo(ua) {
  return /uvideo/i.test(ua);
}
export function isInApp(ua) {
  return isInSnack(ua) || isInUvideo(ua);
}
export function isInKwai(ua) {
  return /Kwai\/|Kwai_Lite\/|Kwai_Pro\//i.test(ua);
}
export var rem = function rem(len) {
  return "".concat(len / 72, "rem");
};
export var vw = function vw(len) {
  return "".concat(len / 7.2, "vw");
};
export var getUd = function getUd() {
  var ud = cookies.get("ud") || "";
  if (!ud) {
    var token = cookies.get("token") || "";
    ud = token.split("-")[1] || "";
  }
  return ud;
};
export function isIOS(ua) {
  return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}
export function isAndroid(ua) {
  return ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1;
}
export function isSamsung(ua) {
  return !!ua.match(/sm-/i) || !!ua.match(/C3600/i);
}
export function isMoto(ua) {
  return !!ua.match(/Moto/i);
}
export function isLogin(ua) {
  var token = cookies.get("token") || "";
  var ud = cookies.get("ud") || "";
  return isIOS(ua) && !!token || isAndroid(ua) && ud !== "0" && !!ud;
}
export var appver = Cookie.getItem("appver") || "10000.0.0";
// eslint-disable-next-line import/no-mutable-exports
var ud = Cookie.getItem("ud") || "";
if (!ud) {
  var token = Cookie.getItem("token") || "";
  ud = token.split("-")[1] || "";
}
export { ud };
/**
 * 比较版本号
 * @param  {string} source 比较源
 * @param  {string} target 比较目标
 * @return {inter} 前者大返回>0, 相等返回0, 前者小返回<0
 */
export function compareAppVersion(source, target) {
  var sourceArr = source.split(".");
  var targetArr = target.split(".");
  for (var i = 0; i < sourceArr.length; i++) {
    if (sourceArr[i] !== targetArr[i]) {
      sourceArr[i] = isNaN(sourceArr[i]) ? 0 : sourceArr[i];
      targetArr[i] = isNaN(targetArr[i]) ? 0 : targetArr[i];
      return sourceArr[i] - targetArr[i];
    }
  }
  return 0;
}
export var rxp2px = function rxp2px(len) {
  return len / 720 * window.innerWidth;
};
export var getpx = function getpx(len) {
  return len / 414 * window.innerWidth;
};
export var kpn = cookies.get("kpn") || new URL(location.href).searchParams.get("kpn") || "KWAI";
export var getKpn = function getKpn() {
  return cookies.get("kpn") || new URL(location.href).searchParams.get("kpn") || "KWAI";
};
export var isKwai = kpn === "KWAI";
export var isSnack = kpn === "KWAI_BULLDOG";
export var isKwaiMe = kpn === "KWAI.ME";
export var getIsKwai = function getIsKwai() {
  return getKpn() === "KWAI";
};
export var getIsSnack = function getIsSnack() {
  return getKpn() === "KWAI_BULLDOG";
};
export var getIsKwaiMe = function getIsKwaiMe() {
  return getKpn() === "KWAI.ME";
};
var getSTHeight = function getSTHeight() {
  // 获取userAgent里的titleHeight和statusHeight
  var userAgent = navigator.userAgent;
  var statueHTReg = /StatusHT\/\d+/g;
  var titleHTReg = /TitleHT\/\d+/g;
  var SHTArr = userAgent.match(statueHTReg);
  var THTArr = userAgent.match(titleHTReg);
  return SHTArr !== null && THTArr !== null ? [Number(SHTArr[0].split("/")[1]), Number(THTArr[0].split("/")[1])] : [];
};
export var getNavHeight = function getNavHeight() {
  var defaultHeight = 50;
  var adjust = 8;
  var _getSTHeight = getSTHeight(),
    _getSTHeight2 = _slicedToArray(_getSTHeight, 2),
    statusH = _getSTHeight2[0],
    titleH = _getSTHeight2[1];
  return statusH + titleH ? statusH + titleH + adjust : defaultHeight;
};
/** 设备Id */
export var deviceId = cookies.get("did") || "";
export var getDeviceId = function getDeviceId() {
  return cookies.get("did") || "";
};
export function isURL(URL) {
  var str = URL;
  // eslint-disable-next-line no-useless-escape
  var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  var objExp = new RegExp(Expression);
  if (objExp.test(str) === true) {
    return true;
  }
  return false;
}
export var getUserId = function getUserId() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "userId";
  var userId = getQuery(name) || cookies.get("userId") || cookies.get("ud");
  if (userId) {
    return Number(userId);
  }
  return undefined;
};
export var userAgent = navigator.userAgent;
export var statusBarHeight = (userAgent.match(/.*StatusHT\/(\d+).*/) || [userAgent, -1])[1];
// metaui的
export var MNavBaroffsetTop = getpx(44) + Number(statusBarHeight);
/*
{
    "tur": "土耳其桶",
    "br": "巴西桶",
    "usa": "美国桶",
    "zh_HK": "香港桶",
    "lka": "斯里兰卡",
    "ru": "俄罗斯桶",
    "th": "泰国桶",
    "spa": "西班牙桶",
    "in": "印度尼西亚桶",
    "egy": "埃及",
    "npl": "尼泊尔",
    "pak": "巴基斯坦",
    "bgd": "孟加拉国"
}
*/
export var getCountry = function getCountry() {
  return cookies.get("countryInfo") || "";
};
// ISLP/1 低端机  ISLP/0 高端机
export var isLowPhone = !/ISLP\/0/.test(navigator.userAgent) || Number(cookies.get("deviceScore")) <= 26;
export var getBucket = function getBucket() {
  var url = new URL(window.location.href);
  return cookies.get("bucket") || url.searchParams.get("bucket") || url.searchParams.get("shareBucket") || "";
};
// 13.12.1这种
export var getIosVersion = function getIosVersion() {
  var ua = navigator.userAgent.toLowerCase();
  var matched = ua.match(/cpu iphone os (.*?) like mac os/);
  return matched ? matched[1].split("_").join(".") : null;
};