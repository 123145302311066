function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// 跳转onelink
import CallApp from '@ks/call-app';
import yoda from '@ks/yoda-js-sdk';
import { addParam, loadUrl } from './yoda';
import { kpn } from './device';
import { getShareId } from './share';
export var getOneLink = function getOneLink(params) {
  var onelink;
  var af_channel = 'creator';
  if (kpn === 'KWAI') {
    onelink = 'https://go.onelink.me/iGO2';
  } else if (kpn === 'KWAI_BULLDOG') {
    af_channel = 'share';
    onelink = 'https://sharefor2.onelink.me/Z4b7';
  } else if (kpn === 'KWAI.ME') {
    onelink = 'https://kwaime.onelink.me/V16i';
  }
  var targetUrl = encodeURIComponent(location.href);
  var af_sub1 = JSON.stringify({
    'share_id': getShareId(),
    shareInfo: {
      'target_url': targetUrl
    }
  });
  // onelink的url，scheme是客户端scheme的url
  var realParams = params ? _objectSpread({
    pid: 'H5',
    af_channel: af_channel,
    af_web_dp: 'https://play.google.com/store/apps/details?id=com.kwai.video',
    af_sub1: af_sub1
  }, params) : {
    pid: 'H5',
    af_channel: af_channel,
    af_web_dp: 'https://play.google.com/store/apps/details?id=com.kwai.video',
    af_sub1: af_sub1
  };
  onelink = addParam(onelink, realParams);
  return onelink;
};
// path: 不加协议
export function callApp(option) {
  var _option$protocol = option.protocol,
    protocol = _option$protocol === void 0 ? 'ikwai' : _option$protocol,
    host = option.host,
    onelink = option.onelink,
    path = option.path,
    param = option.param,
    _option$timeout = option.timeout,
    timeout = _option$timeout === void 0 ? 3000 : _option$timeout;
  var callApp = new CallApp({
    scheme: {
      protocol: protocol,
      host: host
    },
    appstore: onelink,
    fallback: onelink,
    timeout: timeout
  });
  callApp.open({
    path: path,
    param: param
  });
}
export function jumpOneLink(url) {
  var scheme = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'home';
  callApp({
    // path: `webview?url=${encodeURIComponent(scheme)}&target_url=${encodeURIComponent(location.href)}`,
    path: scheme,
    onelink: url
  });
}
/**
 *
 * @param scheme    ikwai://post 或者https://www.baidu.com
 * @returns
 */
export function jumpOneLinkForCommon(scheme) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var callScheme = scheme;
  // 1.端内，直接调起
  if (yoda.isInYoda) {
    loadUrl(callScheme);
    return;
  }
  // 2.端外
  // 2.1 处理ikwai协议,ikwai://post --> post
  if (/^ikwai/.test(callScheme)) {
    callScheme = callScheme.slice(8);
  }
  // 2.2 h5链接，虽然周报没用到
  else {
    callScheme = "webview?url=".concat(encodeURIComponent(callScheme));
  }
  // eslint-disable-next-line max-len
  callScheme = addParam(callScheme, {
    target_url: location.href
  });
  callApp(_objectSpread({
    onelink: getOneLink(),
    path: callScheme
  }, options));
}
/**
 *
 * @param scheme    ikwai://post 或者https://www.baidu.com
 * @returns
 */
export function jumpOneLinkForWeekly(scheme) {
  var callScheme = scheme;
  // 1.端内，直接调起
  if (yoda.isInYoda) {
    loadUrl(callScheme);
    return;
  }
  // 2.端外
  // 2.1 处理ikwai协议,ikwai://post --> post
  if (/^ikwai/.test(callScheme)) {
    callScheme = callScheme.slice(8);
  }
  // 2.2 h5链接，虽然周报没用到
  else {
    callScheme = "webview?url=".concat(encodeURIComponent(callScheme), "}");
  }
  // eslint-disable-next-line max-len
  callScheme = addParam(callScheme, {
    target_url: location.href
  });
  callApp({
    onelink: getOneLink(),
    path: callScheme
  });
}
// 作者裂变onelink
export function jumpOneLinkForScout(scheme) {
  var callScheme = scheme;
  // 1.端内，直接调起
  if (yoda.isInYoda) {
    loadUrl(callScheme);
    return;
  }
  // 2.端外
  // 2.1 处理ikwai协议,ikwai://post --> post
  if (/^ikwai/.test(callScheme)) {
    callScheme = callScheme.slice(8);
  }
  // 2.2 h5链接
  else {
    callScheme = "webview?url=".concat(encodeURIComponent(callScheme));
  }
  var target_url_obj = new URL(location.href);
  target_url_obj.searchParams.append('showDialog', 'false');
  // eslint-disable-next-line max-len
  callScheme = addParam(callScheme, {
    // 俩参数都是业务需要
    target_url: target_url_obj.href
  });
  callApp({
    onelink: getOneLink({
      pid: 'H5',
      af_channel: 'share',
      c: 'creator_scout',
      af_adset: 'button'
    }),
    path: callScheme
  });
}