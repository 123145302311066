import K18nUtils from '@k18n/k18n-utils';
// 多个页面（指不在一个打包文件）用到的，放这
export var langs = require('@k18n/operation-creator-monetization-h5-language/tags/revenue-program/index.json');
var commonLang = new K18nUtils({
  messages: [langs]
});
var t = function t(key, params) {
  if (params) {
    if (params instanceof Array) {
      var realParams = {};
      params.forEach(function (param, index) {
        realParams[index] = param;
      });
      return commonLang.getText({
        id: key,
        params: realParams
      });
    }
    return commonLang.getText({
      id: key,
      params: params
    });
  }
  return commonLang.getText({
    id: key
  });
};
commonLang.t = t;
export default commonLang;