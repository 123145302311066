export function addParam(url, params) {
  var sp = url.indexOf('?') === -1 ? '?' : '&';
  var arr = [];
  for (var key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      var value = params[key] === undefined ? '' : params[key];
      arr.push("".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(value)));
    }
  }
  if (arr.length) {
    url += sp + arr.join('&');
  }
  return url;
}
export function parseQuery() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.href;
  url = url.split('#')[0];
  var arr = url.split('?');
  var queryString = arr[arr.length - 1];
  var entrys = queryString === url ? [] : queryString.split('&');
  var res = {};
  for (var i = 0; i < entrys.length; i++) {
    var entry = entrys[i];
    var arr1 = entry.split('=');
    if (arr1[0]) {
      res[decodeURIComponent(arr1[0])] = decodeURIComponent(arr1[1] || '');
    }
  }
  return res;
}
export var getQueryParams = function getQueryParams() {
  var url = location.search || ''; // 获取url中"?"符后的字串
  // 处理客户端直播间拼接参数出现两个问号的情况，临时处理将author_id前面的?改为&
  var str = url.substr(1).replace(/\?author_id/, '&author_id');
  var theRequest = Object.create(null);
  var strs = str.split('&').filter(Boolean);
  for (var i = 0; i < strs.length; i++) {
    theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
  }
  return theRequest;
};
export var getQuery = function getQuery(name) {
  return new URL(window.location.href).searchParams.get(name);
};