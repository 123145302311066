var Cookie = {
  getItem: function getItem(sKey) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*".concat(encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&'), "\\s*\\=\\s*([^;]*).*$)|^.*$")), '$1')) || null;
  },
  setItem: function setItem(sKey, sValue, option) {
    var vEnd = option.vEnd,
      sPath = option.sPath,
      sDomain = option.sDomain,
      bSecure = option.bSecure;
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    var sExpires = vEnd && "; expires=".concat(vEnd.toUTCString()) || '';
    document.cookie = "".concat(encodeURIComponent(sKey), "=").concat(encodeURIComponent(sValue)).concat(sExpires).concat(sDomain ? "; domain=".concat(sDomain) : '').concat(sPath ? "; path=".concat(sPath) : '').concat(bSecure ? '; secure' : '');
    return true;
  },
  removeItem: function removeItem(sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) {
      return false;
    }
    document.cookie = "".concat(encodeURIComponent(sKey), "=; expires=Thu, 01 Jan 1970 00:00:00 GMT").concat(sDomain ? "; domain=".concat(sDomain) : '').concat(sPath ? "; path=".concat(sPath) : '');
    return true;
  },
  hasItem: function hasItem(sKey) {
    return new RegExp("(?:^|;\\s*)".concat(encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&'), "\\s*\\=")).test(document.cookie);
  }
};
export default Cookie;