import _Loading from "@kibt/meta-ui/es/loading";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.async-iterator.js";
import "core-js/modules/es.symbol.has-instance.js";
import "core-js/modules/es.symbol.is-concat-spreadable.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.match.js";
import "core-js/modules/es.symbol.match-all.js";
import "core-js/modules/es.symbol.replace.js";
import "core-js/modules/es.symbol.search.js";
import "core-js/modules/es.symbol.species.js";
import "core-js/modules/es.symbol.split.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.symbol.to-string-tag.js";
import "core-js/modules/es.symbol.unscopables.js";
import "core-js/modules/es.aggregate-error.js";
import "core-js/modules/es.array.at.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.copy-within.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.last-index-of.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.of.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.reduce-right.js";
import "core-js/modules/es.array.reverse.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.species.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.array-buffer.constructor.js";
import "core-js/modules/es.array-buffer.is-view.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.date.to-json.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.has-instance.js";
import "core-js/modules/es.global-this.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.json.to-string-tag.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.math.acosh.js";
import "core-js/modules/es.math.asinh.js";
import "core-js/modules/es.math.atanh.js";
import "core-js/modules/es.math.cbrt.js";
import "core-js/modules/es.math.clz32.js";
import "core-js/modules/es.math.cosh.js";
import "core-js/modules/es.math.expm1.js";
import "core-js/modules/es.math.fround.js";
import "core-js/modules/es.math.hypot.js";
import "core-js/modules/es.math.imul.js";
import "core-js/modules/es.math.log10.js";
import "core-js/modules/es.math.log1p.js";
import "core-js/modules/es.math.log2.js";
import "core-js/modules/es.math.sign.js";
import "core-js/modules/es.math.sinh.js";
import "core-js/modules/es.math.tanh.js";
import "core-js/modules/es.math.to-string-tag.js";
import "core-js/modules/es.math.trunc.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.epsilon.js";
import "core-js/modules/es.number.is-finite.js";
import "core-js/modules/es.number.is-integer.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.is-safe-integer.js";
import "core-js/modules/es.number.max-safe-integer.js";
import "core-js/modules/es.number.min-safe-integer.js";
import "core-js/modules/es.number.parse-float.js";
import "core-js/modules/es.number.parse-int.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.object.define-getter.js";
import "core-js/modules/es.object.define-setter.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.from-entries.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.get-own-property-names.js";
import "core-js/modules/es.object.get-prototype-of.js";
import "core-js/modules/es.object.has-own.js";
import "core-js/modules/es.object.is.js";
import "core-js/modules/es.object.is-extensible.js";
import "core-js/modules/es.object.is-frozen.js";
import "core-js/modules/es.object.is-sealed.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.lookup-getter.js";
import "core-js/modules/es.object.lookup-setter.js";
import "core-js/modules/es.object.prevent-extensions.js";
import "core-js/modules/es.object.seal.js";
import "core-js/modules/es.object.set-prototype-of.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.parse-float.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.promise.all-settled.js";
import "core-js/modules/es.promise.any.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.reflect.apply.js";
import "core-js/modules/es.reflect.construct.js";
import "core-js/modules/es.reflect.define-property.js";
import "core-js/modules/es.reflect.delete-property.js";
import "core-js/modules/es.reflect.get.js";
import "core-js/modules/es.reflect.get-own-property-descriptor.js";
import "core-js/modules/es.reflect.get-prototype-of.js";
import "core-js/modules/es.reflect.has.js";
import "core-js/modules/es.reflect.is-extensible.js";
import "core-js/modules/es.reflect.own-keys.js";
import "core-js/modules/es.reflect.prevent-extensions.js";
import "core-js/modules/es.reflect.set.js";
import "core-js/modules/es.reflect.set-prototype-of.js";
import "core-js/modules/es.reflect.to-string-tag.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.at-alternative.js";
import "core-js/modules/es.string.code-point-at.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.from-code-point.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.match-all.js";
import "core-js/modules/es.string.pad-end.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.string.raw.js";
import "core-js/modules/es.string.repeat.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.replace-all.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.string.trim-end.js";
import "core-js/modules/es.string.trim-start.js";
import "core-js/modules/es.typed-array.float32-array.js";
import "core-js/modules/es.typed-array.float64-array.js";
import "core-js/modules/es.typed-array.int8-array.js";
import "core-js/modules/es.typed-array.int16-array.js";
import "core-js/modules/es.typed-array.int32-array.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.uint8-clamped-array.js";
import "core-js/modules/es.typed-array.uint16-array.js";
import "core-js/modules/es.typed-array.uint32-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.from.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.of.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.weak-map.js";
import "core-js/modules/es.weak-set.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.immediate.js";
import "core-js/modules/web.queue-microtask.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue';
import './k18n';
import CommonK18n from '@/utils/common-k18n';
import * as handleError from '@/utils/handle-error';
Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
handleError.setI18n(CommonK18n);
Vue.use(_Loading);
if (process.env.VUE_APP_CONSOLE === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  var VConsole = require('vconsole');
  new VConsole();
}
new Vue({
  render: function render(h) {
    return h(App);
  }
}).$mount('#app-container');